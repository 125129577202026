import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  NovistoCommonModule,
  CommonTranslateModule,
  FormatNumberBrowserLocalePipe,
  UnitSymbolPipe,
  UnitsService,
  LoaderStateService,
  TranslateService,
  FeatureFlagService,
  AuthApiService,
} from '@novisto/common';

import { AppComponent } from './app.component';
import { AppConfig } from './shared/models';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor/auth.interceptor';
import { EmbedderService } from './shared/services/embedder/embedder.service';
import { EmbedderUtils } from './shared/utilities/embedder-utils';
import { environment } from '../environments/environment';
import { ExcelEmbedderService } from './shared/services/excel-embedder/excel-embedder.service';
import { PublicCoreApiService } from './shared/services/public-core-api/public-core-api.service';
import { PublicDocumentsService } from './shared/services/public-documents/public-documents.service';
import { PublicIndicatorsService } from './shared/services/public-indicators/public-indicators.service';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedErrorInterceptor } from './shared/interceptors/unauthorized-error-interceptor/unauthorized-error.interceptor';
import { WordEmbedderService } from './shared/services/word-embedder/word-embedder.service';
import { PublicFiscalYearsService } from './shared/services/public-fiscal-years/public-fiscal-years.service';
import { PublicSourcesService } from './shared/services/public-source/public-source.service';
import { PublicFeatureFlagService } from './shared/services/public-feature-flag/public-feature-flag.service';
import { NgxMatomoModule } from 'ngx-matomo-client';
import { AuthService } from './shared/services/auth/auth.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonTranslateModule,
    NovistoCommonModule.forRoot(environment as unknown as AppConfig),
    SharedModule,
    NgxMatomoModule.forRoot({ siteId: '', trackerUrl: '', disabled: true }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedErrorInterceptor,
      multi: true,
    },
    { provide: UnitsService, deps: [PublicCoreApiService] },
    FormatNumberBrowserLocalePipe,
    UnitSymbolPipe,
    { provide: FeatureFlagService, useClass: PublicFeatureFlagService },
    { provide: AuthApiService, useClass: AuthService },
    {
      provide: EmbedderService,
      deps: [
        FormatNumberBrowserLocalePipe,
        LoaderStateService,
        PublicDocumentsService,
        PublicFiscalYearsService,
        PublicIndicatorsService,
        PublicSourcesService,
        TranslateService,
        UnitSymbolPipe,
      ],
      useFactory: (
        formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
        loaderStateService: LoaderStateService,
        publicDocumentsService: PublicDocumentsService,
        publicFiscalYearsService: PublicFiscalYearsService,
        publicIndicatorsService: PublicIndicatorsService,
        publicSourcesService: PublicSourcesService,
        translateService: TranslateService,
        unitSymbolPipe: UnitSymbolPipe,
      ) => {
        const Embedder = EmbedderUtils.isWord() ? WordEmbedderService : ExcelEmbedderService;

        return new Embedder(
          formatNumberBrowserLocalePipe,
          loaderStateService,
          publicDocumentsService,
          publicFiscalYearsService,
          publicIndicatorsService,
          publicSourcesService,
          translateService,
          unitSymbolPipe,
        );
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
